import React from "react"
import Layout from "../components/Layout";
import { useStaticQuery, graphql } from "gatsby";
import { Container, Row, Column, fonts } from '../styles';
import Section from '../components/Section';
import ScrollSpy from "../components/ScrollSpy";
import SplitSection from "../components/SplitSection";
import ContactForm from '../components/ContactForm';

const ContactPage = () => {
    const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          contact {
            address {
                zip
                street
                state
                city
            }
            email
            phone
          }
        }
      }
    }
  `);
  const phone = data.site.siteMetadata.contact.phone;
  const phoneLink = 'tel:' + phone.match(/\d+/g).join('');
  const email = data.site.siteMetadata.contact.email;
  const mailLink = 'mailto:' + email;
  const address = data.site.siteMetadata.contact.address;

    return (
        <Layout>
          <main>
            <Section theme='dark' style={{ paddingTop: 200 }}>
                <Container>
                    <h1>Contact Us</h1>
                    <h4 style={{ fontFamily: fonts.body, textTransform: 'none' }}>Looking to switch to solar energy, or interested in becoming part of the SEP team? Give us a call or fill out the form below for more information.</h4>
                    <Row alignX='space-between'>
                        <Column sm={30}>
                            <h3>Address</h3>
                            <p>{address.street} {address.city}, {address.state} {address.zip}</p>
                        </Column>
                        <Column sm={30}>
                            <h3>Phone</h3>
                            <p><a href={phoneLink}>{ phone }</a></p>
                        </Column>
                        <Column sm={30}>
                            <h3>E-Mail</h3>
                            <p><a href={mailLink}>{ email }</a></p>
                        </Column>
                    </Row>
                </Container>
            </Section>
            <Section>
                <Container>
                    <h2>Get In Touch</h2>
                    <ContactForm />
                </Container>
            </Section>
          </main>
        </Layout>
    )

}

export default ContactPage